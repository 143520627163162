<template>
  <div id="container">
    <SHeader @download="onDownload" :channel="channel"></SHeader>
    <main>
      <!-- Banner -->
      <div class="banner">
        <carousel-3d ref="slideBanner" class="carousel-container" v-if="wallpaperList && wallpaperList.length" autoplay :autoplayTimeout="6000" :autoplayHoverPause="true" :animationSpeed="500" :minSwipeDistance="9999" @after-slide-change="onSildeChange" :space="600" :border="0" :display="3" :perspective="2" :width="654" :height="368">
          <slide class="carousel-slide" v-for="(item, index) in wallpaperList" :key="index" :index="index">
            <div class="carousel-image-wrapper" v-show="carousel3dShow >= wallpaperList.length - 1">
              <video muted="muted" loop="loop" :src="item.video_url" @loadedmetadata="videoLoaded(index)"></video>
            </div>
          </slide>
        </carousel-3d>
        <div class="carousel-prev" @click="onBannerPrev" v-show="carousel3dShow >= wallpaperList.length - 1"></div>
        <div class="carousel-next" @click="onBannerNext" v-show="carousel3dShow >= wallpaperList.length - 1"></div>
      </div>
      <!-- 壁纸 -->
      <div class="wallpaper">
        <ul class="wallpaper-tag-list">
          <li class="tag-item" :class="{ active: activeTagId == item.id }" v-for="item in tags" :key="item.id" @click="onTagClick(item.id)">{{ item.tag }}</li>
        </ul>
        <ul class="wallpaper-list">
          <li class="wallpaper-item" v-for="(item, index) in wallpaperListPage" :key="index" @click.stop="onWallpaperClick(item)">
            <div class="image-wrapper">
              <img :src="item.img_url" alt="" />
            </div>
            <div class="wallpaper-title">{{ item.paper_name }}</div>
            <!-- <div class="wallpaper-download" :class="{ 'is-baidu': isBaidu }" @click.stop="onDownload">
                <div class="icon-download"></div>
                立即下载
              </div> -->
          </li>
        </ul>
        <div class="wallpaper-more" :class="{ 'is-baidu': isBaidu, 'is-360':is360, 'is-qihu': isQihu, 'is-qihu2': isQihu2 }" @click="onDownload" id="bd1">下载查看更多壁纸</div>
      </div>
      <!-- 美化 -->
      <div class="beautify">
        <div class="beautify-title"></div>
        <div class="beautify-content" :style="`cursor: url(${currentCursor}), auto`">
          <div class="beautify-content-inner">
            <div class="beautify-banner">
              <div class="beautify-left">
                <img :src="currentBeautify.mouse_img" alt="" />
              </div>
              <div class="beautify-middle">
                <img :src="currentBeautify.start_menu_img" alt="" />
              </div>
              <div class="beautify-right">
                <img :src="currentBeautify.icon_img" alt="" />
              </div>
            </div>
            <div class="beautify-previews">
              <carousel-3d ref="slideBeautify" class="carousel-container" v-if="beautify && beautify.length" :animationSpeed="0" @after-slide-change="onBeautifyChanged" :space="222" :disable3d="true" :border="0" :display="5" :width="202" :height="122">
                <slide class="carousel-slide" v-for="(item, index) in beautify" :key="index" :index="index">
                  <div class="carousel-image-wrapper" :class="{ active: currentBeautify.id == item.id }" :data-mouse="item.mouse_ico" @click="onCurrentBeautifyChange(item)">
                    <img :src="item.start_menu_img" />
                  </div>
                </slide>
              </carousel-3d>
              <div class="carousel-prev" @click="$refs.slideBeautify.goPrev()"></div>
              <div class="carousel-next" @click="$refs.slideBeautify.goNext()"></div>
            </div>
          </div>
          <div class="beautify-download" :class="{ 'is-baidu': isBaidu, 'is-360':is360, 'is-qihu': isQihu, 'is-qihu2': isQihu2 }" @click="onDownload" id="bd1">立即下载</div>
        </div>
      </div>
      <!-- 功能 -->
      <div class="features">
        <div class="features-title"></div>
        <div class="features-content">
          <div class="features-content-inner">
            <div class="fetaures-carousel">
              <carousel-3d ref="slideFeatures" class="carousel-container" :minSwipeDistance="9999" :animationSpeed="0" :border="0" :display="1" :disable3d="true" :perspective="0" :width="712" :height="400">
                <slide class="carousel-slide" :index="0">
                  <Pscreen ref="screen"></Pscreen>
                </slide>
                <slide class="carousel-slide" :index="1">
                  <PTaskBar ref="taskbar"></PTaskBar>
                </slide>
                <slide class="carousel-slide" :index="2">
                  <PSpectrum></PSpectrum>
                </slide>
                <slide class="carousel-slide" :index="3">
                  <PTodo ref="todo"></PTodo>
                </slide>
              </carousel-3d>
              <!-- <div v-show="[0, 2].includes(featureActive)" class="carousel-prev" @click="onFeaturePrev"></div>
                <div v-show="[0, 2].includes(featureActive)" class="carousel-next" @click="onFeatureNext"></div> -->
            </div>
            <div class="fetaures-card">
              <div>
                <img class="card-bg" :src="feature.info[featureActive].bg" />
                <div class="card-title">{{ feature.info[featureActive].title }}</div>
                <div class="card-info">{{ feature.info[featureActive].description }}</div>
              </div>

              <div class="btn-list">
                <div class="btn-list-item" v-for="(item, index) in feature.btns" :key="index" :class="{ active: featureActiveNext == index }" @click="onFeatureBtnClick(index, item)">
                  <div class="btn-animation"></div>
                  <div class="btn-content">{{ item }}</div>
                  <div class="btn-active" v-show="index == featureActive"></div>
                </div>
              </div>
            </div>
            <div class="features-download" :class="{ 'is-baidu': isBaidu, 'is-360':is360, 'is-qihu': isQihu, 'is-qihu2': isQihu2 }" @click="onDownload" id="bd1">立即下载</div>
          </div>
        </div>
      </div>
    </main>
    <downModelHome @download="onDownload" :channel="channel"></downModelHome>
    <!-- <div class="float-win">
      <div class="float-btn" :class="{ 'is-baidu': isBaidu, 'is-360':is360, 'is-qihu': isQihu, 'is-qihu2': isQihu2 }" @click="onDownload" id="bd1"></div>
    </div> -->
    <div class="dialog-wrapper" :class="{ show: showDialog }" @click="() => (showDialog = false)">
      <div class="dialog" @click.stop="() => null">
        <img :src="wallpaperPreview" alt="" />
        <div class="dialog-close" @click="() => (showDialog = false)"></div>
      </div>
    </div>
    <!-- https://www.apumao.net.cn/ 显示 -->
    <SFooter v-if="!isUrlVip"/>
     <!-- https://www.apumao.vip/ 显示 -->
    <SFooterVip v-else></SFooterVip>
  </div>
</template>

<script>
import { apiBanner, apiSelected, apiBeautify, apiDownload } from '@/api/channel'
import SFooter from '@/components/SFooter/foot2'
import SFooterVip from '@/components/SFooter/foot3'
import SHeader from '@/components/SHeader'
import PTaskBar from './PTaskBar.vue'
import PSpectrum from './PSpectrum.vue'
import PTodo from './PTodo.vue'
import Pscreen from './Pscreen.vue'
import downModelHome from './bd01/downModelHome.vue'
export default {
  props: {
    channel: {
      type: Number,
      default: null,
    },
  },
  components: {
    SFooter,
    SHeader,
    PTaskBar,
    PSpectrum,
    PTodo,
    SFooterVip,
    Pscreen,
    downModelHome
  },
  data: () => ({
    details: [],
    wallpaperList: [],
    wallpaperListPage: [],
    carousel3dShow:0,
    isUrlVip:false,//域名是否 https://www.apumao.vip/
    beautify: [],
    official: [],
    tags: [
      { id: 0, tag: '精选' },
      { id: 12, tag: 'ACG动漫' },
      { id: 7, tag: '遇见美景' },
      { id: 10, tag: '科幻探索' },
      { id: 5, tag: '幻想女友' },
      { id: 2, tag: '游戏风象' },
      { id: 14, tag: '学习圈子' },
      { id: 18, tag: '国漫天地' },
      { id: 17, tag: '磕糖专区' },
      { id: 19, tag: '军事体育' },
    ],
    // tags: { 0: '精选', 12: 'ACG动漫', 7: '遇见美景', 10: '科幻探索', 5: '幻想女友', 2: '游戏风象', 14: '学习圈子', 18: '国漫天地', 17: '磕糖专区', 19: '军事体育' },
    wallpaperPreview: null,
    showDialog: false,
    activeTagId: 0,
    currentBannerIndex: 0,
    currentCursor: null,
    currentActiveBanner: 0,
    featureActive: 0,
    featureActiveNext: 1,
    currentBeautify: {},
    timer: null,
    timerBanner: null,
    timerPlay: null,
    timerResize: null,
    feature: {
      info: [
        {
          title: '屏保',
          bg: require('../assets/images/item-f.png'),
          description: '炫酷动态屏保守护电脑屏幕，遮挡桌面隐私！',
        },
        {
          title: '任务栏优化',
          bg: require('../assets/images/item-c.png'),
          description: '自定义颜色、位置、透明度，随心调整任务栏。',
        },
        {
          title: '音乐频谱',
          bg: require('../assets/images/item-d.png'),
          description: '用音乐装饰桌面，让节奏带来活力。',
        },
        {
          title: '待办清单',
          bg: require('../assets/images/item-e.png'),
          description: '电脑上的小助手，重要事项从此不再错过。',
        },
      ],
      btns: ['屏保', '任务栏优化', '音乐频谱', '待办清单'],
    },
  }),
  computed: {
    isBaidu() {
      return this.channel == 20210426043
    },
    is360() {
      return this.channel == 15170001125
    },
    isQihu() {
      return this.channel == 20200707006
    },
    isQihu2() {
      return this.channel == 2887211655
    },
  },
  methods: {
    onBannerPrev() {
      if (this.timerBanner) return
      this.$refs.slideBanner.goPrev()
      this.timerBanner = setTimeout(() => {
        clearTimeout(this.timerBanner)
        this.timerBanner = null
      }, 500)
    },
    videoLoaded(index){
      this.carousel3dShow ++
    },
    onBannerNext() {
      if (this.timerBanner) return
      this.$refs.slideBanner.goNext()
      this.timerBanner = setTimeout(() => {
        clearTimeout(this.timerBanner)
        this.timerBanner = null
      }, 500)
    },
    onBeautifyPrev() {
      // this.$refs.slideBeautify.prev()
    },
    onBeautifyNext() {
      // this.$refs.slideBeautify.next()
    },
    onBeautifyChanged(index) {
      this.currentBeautify = this.beautify[index]
      this.currentCursor = this.beautify[index].mouse_ico
    },
    onFeaturePrev() {
      this.$refs.taskbar.prev()
    },
    onFeatureNext() {
      this.$refs.taskbar.next()
    },
    onFeatureBtnClick(index, item) {
      this.goFeature(index)
      this.playFeatures()
      this.$refs.todo.pause()
      this.$refs.taskbar.pause()
      this.$refs.screen.pause()
    },
    goFeature(index) {
      if (this.feature.btns.length && index < this.feature.btns.length) {
        this.featureActive = this.featureActiveNext = index
        this.featureActive < this.feature.btns.length - 1 ? this.featureActiveNext++ : (this.featureActiveNext = 0)
        this.$refs.slideFeatures.goFar(index)
      }
    },
    async onDownload() {
      const { n: channel, name } = this.$route.query
      const resp = await apiDownload({ zsdd: channel || this.channel })
      window.location.href = resp.data.url
      window._czc.push(['_trackEvent', '下载', '点击下载', channel || this.channel])
    },
    playFeatures() {
      this.timer && clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.feature.btns.length) {
          this.featureActive < this.feature.btns.length - 1 ? this.featureActive++ : (this.featureActive = 0)
          this.featureActive < this.feature.btns.length - 1 ? this.featureActiveNext++ : (this.featureActiveNext = 0)
          this.$refs.slideFeatures.goNext()
        }
      }, 12000)
    },
    onTagClick(id) {
      this.activeTagId = id
      this.getWallpaperList()
    },
    onSildeChange(current) {
      try {
        const el = this.$el
        const videos = el.querySelectorAll('.banner video')
        videos.forEach(item => item.pause())
        videos[current].play()
        this.currentActiveBanner == current
      } catch (error) {
        console.log('error');
      }
    },
    onWallpaperClick(item) {
      this.wallpaperPreview = item.img_url
      this.showDialog = true
    },
    onCurrentBeautifyChange(item) {
      this.currentBeautify = item
      this.currentCursor = item.mouse_ico
      // console.log('this.currentCursor', this.currentCursor)
    },
    async getWallpaperList() {
      const { n: channel, name } = this.$route.query
      const resp = await apiSelected({
        name,
        type: this.activeTagId == 0 ? 1 : 2,
        tag_id: this.activeTagId,
      })
      this.wallpaperListPage = resp.data
    },
    async getBanner() {
      const { n: channel, name } = this.$route.query
      this.wallpaperList = (await apiBanner({ channel, name })).data
    },
    async getBeautify() {
      const resp = await apiBeautify()
      this.beautify = resp.data
      this.currentBeautify = this.beautify[0]
      // console.log('beautify', this.beautify)
    },
    async init() {
      this.getisUrlVip()
      await this.getBanner()
      await this.getWallpaperList()
      await this.getBeautify()
      this.playFeatures()
      this.onSildeChange(0)
      this.currentCursor = this.currentBeautify.mouse_ico
    },
    getisUrlVip(){
      if(window.location.href.indexOf('apumao.vip') > -1){
        this.isUrlVip = true
      }else{
        this.isUrlVip = false
      }
    },
    centerDom() {
      if (window.innerWidth > 1280) {
        if (this.timerResize) return
        this.timerResize = setTimeout(() => {
          clearTimeout(this.timerResize)
          this.timerResize = null
          const container = document.querySelector('.mainBody .el-scrollbar__wrap')
          const pad = (1920 - window.innerWidth) / 2.0
          container.scrollLeft = pad
          const header = document.querySelector('.header')
          if (header) {
            // console.log(header);
            // console.log(header.style.padding);
            header.style.padding = `0 ${pad + 48}px`
          }
        }, 300)
      }
    },
  },
  created() {
    this.init()
  },
  mounted() {
    document.body.style.zoom = 1 / window.devicePixelRatio
    // document.getElementById('app').style.transform = 'none'
    // document.getElementById('app').style.width = 'auto'
    // document.getElementById('app').style.height = 'auto'
    // document.querySelector('.mainBody').style.background = 'white'
    // this.centerDom()
    // window.addEventListener('resize', this.centerDom)
    // window.addEventListener('load', this.centerDom)

    document.querySelectorAll('.is-baidu').forEach(item => item.setAttribute('data-agl-cvt', 6))
    document.querySelectorAll('.is-qihu').forEach(item => item.setAttribute('id', 'qh1'))
    document.querySelectorAll('.is-qihu2').forEach(item => item.setAttribute('id', 'qh2'))
  },
  beforeDestroy() {
    document.body.style.zoom = 1
    window.removeEventListener('resize', this.centerDom)
    window.removeEventListener('load', this.centerDom)
  },
}
</script>

<style lang="scss" scoped>
/deep/ #app {
  transform: scale(1) !important;
  width: auto !important;
  height: auto !important;
}
// .layout {
  // position: absolute;
  // top: 0;
  // left: 0;
  // height: 100%;
  // width: 100%;
// }
#container {
  height: 100%;
  width: 1920px;
  margin: 0 auto;
  // width: 100vw;
  // min-width: 1280px;
  overflow: hidden;
  background: white;
  font-family: 'Microsoft YaHei';
}
.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: -999;
  opacity: 0;
  transition: 0.3s ease;
  &.show {
    z-index: 999;
    opacity: 1;
  }
  .dialog {
    width: 894px;
    height: 502px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      border-radius: 20px;
      overflow: hidden;
    }
    .dialog-close {
      position: absolute;
      right: -80px;
      top: 0;
      width: 40px;
      height: 40px;
      background-image: url('../assets/images/icon-close.png');
      background-size: contain;
      cursor: pointer;
      &:hover {
        background-image: url('../assets/images/icon-close-active.png');
      }
    }
  }
}
.float-win {
  position: fixed;
  top: 580px;
  right: 20px;
  width: 184px;
  height: 112px;
  z-index: 999;
  .float-btn {
    position: absolute;
    left: 22px;
    bottom: 16px;
    width: 140px;
    height: 56px;
    background: url('../assets/images/btn-download.png') no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 10px;
    right: 12px;
    bottom: 0;
    border-radius: 10px;
    background-color: white;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../assets/images/mini-window.png');
    background-size: contain;
    background-position: center;
  }
}
.banner {
  position: relative;
  width: 100%;
  height: 474px;
  background-image: url('../assets/images/bg-b.png');
  .carousel-container {
    position: relative;
    transform: translateY(60px);
  }
  .carousel-slide {
    background-color: transparent;
    border: 0;
  }
  .carousel-image-wrapper {
    width: 650px;
    height: 364px;
    border: 2px solid #000000;
    border-radius: 20px;
    overflow: hidden;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .carousel-prev {
    position: absolute;
    left: 480px;
    top: 215px;
    width: 56px;
    height: 60px;
    background-image: url('../assets/images/btn-left.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    pointer-events: none;
    cursor: pointer;
  }
  .carousel-next {
    position: absolute;
    right: 480px;
    top: 215px;
    width: 56px;
    height: 60px;
    background-image: url('../assets/images/btn-right.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    pointer-events: none;
    cursor: pointer;
  }
}
.wallpaper {
  display: flex;

  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  .wallpaper-tag-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 38px;
    .tag-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 40px;
      font-size: 14px;
      color: #111010;
      margin-right: 14px;
      border: 2px solid #d7edff;
      border-radius: 12px;
      cursor: pointer;
      user-select: none;
      &:last-of-type {
        margin-right: 0;
      }
      &.active,
      &:hover {
        color: white;
        font-size: 16px;
        font-weight: bold;
        background-image: url('../assets/images/btn-b.png');
        background-repeat: no-repeat;
        border: 2px solid transparent;
      }
    }
  }
  .wallpaper-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 1168px;
    .wallpaper-item {
      position: relative;
      flex: 0 0 376px;
      width: 0;
      height: 212px;
      margin-right: 20px;
      margin-bottom: 20px;
      cursor: pointer;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border: 2px solid black;
        border-radius: 20px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        z-index: 99;
      }
      &:hover {
        &::after {
          width: calc(100% - 8px);
          height: calc(100% - 8px);
          border: 4px solid #3da7fe;
        }
      }
      .image-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      .wallpaper-download {
        z-index: 999;
      }
      &:hover {
        .wallpaper-title,
        .wallpaper-download {
          opacity: 1;
        }
      }
      .wallpaper-title {
        position: absolute;
        left: 24px;
        top: 12px;
        color: white;
        text-shadow: 0px 0px 2px #292929;
        transition: 0.25s ease-out;
        opacity: 0;
      }
      .wallpaper-download {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 18px;
        width: 164px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(33, 151, 249, 1);
        border-radius: 12px;
        font-size: 18px;
        font-weight: bold;
        color: white;
        transition: 0.25s ease-out;
        opacity: 0;
        .icon-download {
          width: 24px;
          height: 20px;
          margin-right: 12px;
          background-image: url('../assets/images/icon-download.png');
        }
      }
    }
  }
  .wallpaper-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 372px;
    height: 52px;
    margin-top: 20px;
    color: #3da7fe;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    border: 2px solid rgba($color: #3da7fe, $alpha: 0.28);
    border-radius: 16px;
    background: rgba($color: #d7edff, $alpha: 0.28);
    cursor: pointer;
    transition: 0.25s ease;
    &:hover {
      border: 2px solid rgba($color: #3da7fe, $alpha: 1);
    }
  }
}
.beautify {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 38px;
  .beautify-title {
    width: 130px;
    height: 50px;
    margin: 22px;
    background-image: url('../assets/images/beautify.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .beautify-content {
    position: relative;
    width: 100%;
    height: 714px;
    display: flex;
    justify-content: center;
    background-image: url('../assets/images/bg-a.png');
    background-size: contain;
    background-repeat: no-repeat;

    .beautify-content-inner {
      height: 546px;
      width: 1094px;
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      .beautify-banner {
        display: flex;
        justify-content: space-between;
        .beautify-left {
          width: 176px;
          height: 395px;
          border: 2px solid black;
          border-radius: 16px;
          overflow: hidden;
        }
        .beautify-middle {
          width: 702px;
          height: 395px;
          border: 2px solid black;
          border-radius: 16px;
          overflow: hidden;
        }
        .beautify-right {
          width: 176px;
          height: 395px;
          border: 2px solid black;
          border-radius: 16px;
          overflow: hidden;
        }
      }
      .beautify-previews {
        position: relative;
        .carousel-prev,
        .carousel-next {
          position: absolute;
          width: 40px;
          height: 42px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          cursor: pointer;
        }
        .carousel-prev {
          left: -24px;
          bottom: 10px;
          background-image: url('../assets/images/btn-left.png');
        }
        .carousel-next {
          right: -24px;
          bottom: 10px;
          background-image: url('../assets/images/btn-right.png');
        }
        .carousel-slide {
          position: absolute;
          background-color: transparent;
          overflow: visible !important;
          // &::before {
          //   content: '';
          //   position: absolute;
          //   left: -66%;
          //   top: 3px;
          //   width: 20px;
          //   height: 20px;
          //   transform: rotate(45deg);
          //   background-color: #3da7fe;
          //   z-index: 0 !important;
          // }
        }
        .carousel-image-wrapper {
          position: relative;
          border: 2px solid black;
          border-radius: 16px;
          width: 198px;
          height: 110px;
          margin-top: 8px;
          overflow: hidden;
          z-index: 1 !important;
          &.active {
            background: #c8e3f8;
            width: 194px;
            height: 106px;
            border: 4px solid #3da7fe;
            border-radius: 16px;
            img {
              object-fit: cover;
              object-position: center;
              z-index: 1;
            }
          }
        }
        .carousel-slide {
          border-radius: 16px;
        }
        // .beautify-image {
        // }
      }
    }

    .beautify-download {
      position: absolute;
      left: 50%;
      bottom: 42px;
      width: 372px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: bold;
      color: white;
      transform: translateX(-50%);
      background-image: url('../assets/images/btn-a.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        margin-right: 12px;
        margin-top: 6px;
        background-image: url('../assets/images/icon-download.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
.features {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 110px;

  .features-title {
    width: 130px;
    height: 50px;
    margin: 22px;
    background-image: url('../assets/images/features.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .features-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 478px;
    background-image: url('../assets/images/bg-c.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    .fetaures-card {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 634px;
      height: 362px;
      .card-title {
        position: absolute;
        left: 50%;
        top: 30px;
        transform: translateX(-50%);
        margin-left: 6px;
        color: #111010;
        font-size: 28px;
        font-weight: bold;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
      .card-bg {
        position: absolute;
        left: 50%;
        top: 30px;
        transform: translateX(-50%);
        width: 258px;
        height: 64px;
      }
      .card-info {
        position: absolute;
        left: 256px;
        top: 118px;
        font-size: 16px;
        color: #111010;
      }
      .btn-list {
        position: absolute;
        left: 180px;
        bottom: 40px;
        width: 390px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .btn-list-item {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 110px;
          height: 44px;
          margin: 8px;
          background: white;
          border-radius: 10px;
          --border-width: 2px;
          cursor: pointer;
          .btn-active {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 3;
            border: 2px solid black;
            border-radius: 10px;
            background-size: 50% 100%;
            background-image: linear-gradient(180deg, white 0 50%, #f8917c 50% 100%);
            &::before,
            &::after {
              content: '';
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 2px solid black;
              background-color: rgba(61, 167, 254, 1);
            }
            &::before {
              left: -8px;
            }
            &::after {
              right: -8px;
            }
          }
          .btn-content {
            font-size: 16px;
            font-weight: bold;
            color: #111010;
            z-index: 99;
          }
          .btn-animation {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 10px;
            &::before {
              content: '';
              position: absolute;
              left: -50%;
              top: -50%;
              width: 200%;
              height: 200%;
              border: 2px solid rgba($color: #000000, $alpha: 1);
              border-radius: 10px;
              background-repeat: no-repeat;
              background-size: 50% 50%, 50% 50%;
              background-position: 0 0, 100% 0, 100% 100%, 0 100%;
              background-image: linear-gradient(rgba($color: #000000, $alpha: 1), rgba($color: #000000, $alpha: 1)), linear-gradient(rgba($color: #000000, $alpha: 0.2), rgba($color: #000000, $alpha: 0.2)), linear-gradient(rgba($color: #000000, $alpha: 0.2), rgba($color: #000000, $alpha: 0.2)), linear-gradient(rgba($color: #000000, $alpha: 1), rgba($color: #000000, $alpha: 1));
              z-index: 1;
              transform: translateX(-25.5%);
            }
            &::after {
              content: '';
              position: absolute;
              left: var(--border-width);
              top: var(--border-width);
              width: calc(100% - var(--border-width) * 2);
              height: calc(100% - var(--border-width) * 2);
              background-color: #fff;
              opacity: 1;
              border-radius: 8px;
              z-index: 2;
            }
          }
          &.active {
            .btn-animation::before {
              animation: move 12s linear infinite;
            }
          }
          @keyframes move {
            0% {
              transform: translateX(-25.5%);
            }
            100% {
              transform: translateX(25.5%);
            }
          }
        }
      }
    }
    .features-content-inner {
      position: relative;
      width: 1200px;
      height: 100%;

      .features-download {
        position: absolute;
        right: 0;
        top: 34px;
        width: 186px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
        color: white;
        background-image: url('../assets/images/btn-c.png');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
      }
      .fetaures-carousel {
        position: absolute;
        left: 0;
        top: 0;
        .carousel-container {
          position: relative;
          width: 712px;
          height: 400px;
          margin: 0;
          background-color: white;
          border: 2px solid black;
          border-radius: 20px;
          overflow: hidden;
        }
        .carousel-slide {
          border: 0;
          width: 712px;
          height: 400px;
          transform: none !important;
          background-color: transparent;
        }
        .carousel-image-wrapper {
          width: 708px;
          height: 396px;
          border: 2px solid #000000;
          border-radius: 20px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        .carousel-prev {
          position: absolute;
          left: 270px;
          bottom: -22px;
          width: 40px;
          height: 44px;
          background-image: url('../assets/images/btn-left.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          cursor: pointer;
        }
        .carousel-next {
          position: absolute;
          right: 270px;
          bottom: -22px;
          width: 40px;
          height: 44px;
          background-image: url('../assets/images/btn-right.png');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
